import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  computed,
  effect,
  inject,
  input,
  signal,
} from '@angular/core';
import {
  MatSort,
  MatSortModule,
  Sort,
  SortDirection,
} from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { IDesignageDataTableColumns } from '@desquare/interfaces';
import {
  MatCheckboxChange,
  MatCheckboxModule,
} from '@angular/material/checkbox';
import {
  CdkDragDrop,
  CdkDragStart,
  DragDropModule,
} from '@angular/cdk/drag-drop';
import { DeviceDataService } from '@desquare/services';
import { getHierarchicalFieldValue } from './hierarchicalFields.util';
import { LocalStorageService } from 'ngx-webstorage';
import { trigger, transition, style, animate } from '@angular/animations';
import { NgClass, NgTemplateOutlet, TitleCasePipe } from '@angular/common';
import { DateProxyPipe } from '../pipe/pipe/date-proxy.pipe';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import {
  NgbDropdownModule,
  NgbTooltipModule,
  NgbPopoverModule,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { MomentModule } from 'ngx-moment';
import { TableOsTypePipe } from '../pipe/table-os-type/table-os-type.pipe';
import { TableDeviceArrayComponent } from './table-device-array/table-device-array.component';
import { TableGenericArrayComponent } from './table-generic-array/table-generic-array.component';
import { TableStatusIndicatorComponent } from './table-status-indicator/table-status-indicator.component';
import { dragDropCursorUtil } from '@desquare/utils';
import { TranslateService } from '@ngx-translate/core';
import { LoaderComponent } from '../loader/loader.component';
import { TableSkeletonComponent } from './table-skeleton.component';
import _ from 'lodash';
import { TableChildCellComponent } from './table-components/table-child-cell.component';
import { SearchInputComponent } from '../search-input/search-input.component';

interface ITableCOnfig {
  sortColumn?: string;
  sortDir?: SortDirection;
  visibleColumns?: string[];
}

interface IGroup {
  [x: string]: (IGroupedRow | ['__typename'])[];
}
interface IGroupedRow {
  groupName: string;
  value: string;
  isGroup: boolean;
  reduced: boolean;
}

@Component({
  standalone: true,
  selector: 'designage-data-table',
  templateUrl: './designage-data-table.component.html',
  styleUrls: ['./designage-data-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgClass,
    NgTemplateOutlet,
    FormsModule,
    ReactiveFormsModule,
    TableStatusIndicatorComponent,
    TableDeviceArrayComponent,
    TableGenericArrayComponent,
    MatTableModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatCheckboxModule,
    TranslateModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbPopoverModule,
    DragDropModule,
    MomentModule,
    DateProxyPipe,
    TableOsTypePipe,
    LoaderComponent,
    TableSkeletonComponent,
    TableChildCellComponent,
    TitleCasePipe,
    SearchInputComponent,
  ],
  animations: [
    trigger('showHideLoader', [
      transition(':enter', [
        style({ height: '0px' }),
        animate('0.25s ease-out', style({ height: '*' })),
      ]),
      transition(':leave', [
        style({ height: '*' }),
        animate('0.2s ease-in', style({ height: '0px' })),
      ]),
    ]),
    trigger('showHideBulkActions', [
      transition(':enter', [
        style({ height: 0 }),
        animate('0.25s ease-out', style({ height: '*' })),
      ]),
      transition(':leave', [
        style({ height: '*' }),
        animate('0.2s ease-in', style({ height: 0 })),
      ]),
    ]),
  ],
})
export class DesignageDataTableComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  formBuilder = inject(FormBuilder);
  dataSignal = signal<any[]>([]);
  data = input.required<any[]>();

  @Input('showMultiSelect') set showMultiSelectColumn(value: boolean) {
    this.showMultiSelect = value;
    this.setVisibleColumns();
  }
  @Input() customComponent!: TemplateRef<ElementRef>[];
  @Input() customDragClass: string = '';

  sortSignal = signal<Sort>({ active: '', direction: '' });
  searchableColumnsL1: IDesignageDataTableColumns[] = [];
  searchableColumnsL2: IDesignageDataTableColumns[] = [];
  _columns: IDesignageDataTableColumns[] = [];
  @Input() set columns(value: IDesignageDataTableColumns[]) {
    this._columns = value;
    this.searchableColumnsL1 = value.filter((x) => x.type === 'string');
    this.searchableColumnsL2 = [];
    value
      .filter((c) => c.columns?.length)
      .forEach((c) => {
        const internalStrings = c.columns?.filter((cc) => cc.type === 'string');
        if (internalStrings?.length) {
          this.searchableColumnsL2.push({
            fieldName: c.fieldName,
            name: c.name,
            type: c.type,
            visible: c.visible,
            columns: internalStrings,
          });
        }
      });

    // console.log('searchableColumns', this.searchableColumnsL1); //DEBUG
    // console.log('searchableColumns2', this.searchableColumnsL2); //DEBUG
  }
  get columns() {
    return this._columns;
  }
  filterValue = signal<string>('');
  filterForm!: FormGroup;
  reducedGroups = signal<IGroupedRow[]>([]);

  dataSource = computed(() =>
    this.groupBy(
      this.groupByColumn(),
      this.data(),
      this.reducedGroups(),
      this.sortSignal(),
      this.filterValue()
    )
  );

  @Input('alwaysSort') matSortDisableClear = false;
  @Input() columnSelector = true;
  @Input() showFilter = true;
  @Input() showFooter = true;
  @Input() showBulkActions = false;
  @Input() showDeleteRow = false;
  @Input() readOnly = false;
  @Input() dragDisabled = true;
  @Input() dragDisabledByColumns: string[] = ['connectedResourceGroupName'];
  @Input() rowActiveInSlidingPanel = '';
  @Input() selectedRows?: any[];

  @Output() selectedRowsChange = new EventEmitter<any>();

  @Input('configId') tableConfigKey?: string;
  @Input() tableElementId?: string;
  @Input() connectedTableList?: string[];
  @Input() skeletonRows = 5;
  @Input() skeletonColumns = 4;
  loading = input<boolean>();

  // @ViewChild('table') table!: MatTable<any[]>;
  @ViewChild(MatSort) sort!: MatSort;

  @Output() dataDropped = new EventEmitter<any>();
  @Output() rowDelete = new EventEmitter<any>();
  @Output() rowCheckboxChange = new EventEmitter<unknown>();
  @Output() rowClick = new EventEmitter<unknown>();

  showMultiSelect!: boolean;
  visibleColumns: string[] = [];
  showClearFilter = false;

  groupByColumn = signal<IDesignageDataTableColumns | null>(null);
  showGroupBy = computed(() => this.columns.some((x) => x.groupable));

  selection = new SelectionModel<string>(true, []);

  get footerText() {
    if (!this.data()) return 'No data';
    let footerText = `Total: ${this.data().length}`;
    if (this.selection.selected.length > 0)
      footerText += `, selected: ${this.selection.selected.length}`;
    return footerText;
  }

  constructor(
    private deviceDataService: DeviceDataService,
    private localStorageService: LocalStorageService,
    private translate: TranslateService
  ) {
    // effect(() => {
    // console.log('filterValue', this.filterValue());
    // console.log('dataSource', this.dataSource());
    // });
  }

  ngOnInit(): void {
    this.initSearchForm();
    //Set selected rows from input
    if (this.selectedRows) {
      this.selection.select(...this.selectedRows.map((x) => x.id));
    }
  }

  initSearchForm() {
    this.filterForm = this.formBuilder.group({
      search: [null],
    });
  }
  ngAfterViewInit(): void {
    this.loadTableConfig();
  }

  ngOnDestroy(): void {
    dragDropCursorUtil.cursorReset();
  }

  trackBy = (_index: number, item: any) => item.id;

  getDeviceStatusSignal(deviceId: string) {
    return this.deviceDataService.getDeviceStatusSignal(deviceId);
  }

  trackByColumn(index: number, column: IDesignageDataTableColumns): string {
    return column.fieldName;
  }

  dragDisabledRows(row: any) {
    if (!this.dragDisabled) {
      let disabledRow: boolean = this.dragDisabled;
      this.dragDisabledByColumns.forEach((x) => {
        if (x in row) {
          disabledRow = row[x] != null;
        }
      });
      return disabledRow;
    }
    return this.dragDisabled;
  }

  getTableConfigKey() {
    return this.tableConfigKey
      ? `designage-table-${this.tableConfigKey}`
      : undefined;
  }
  loadTableConfig() {
    const configKey = this.getTableConfigKey();
    if (!configKey) return;

    const tableConfig: ITableCOnfig =
      this.localStorageService.retrieve(configKey);

    if (!tableConfig) {
      // no config, default
      this.setVisibleColumns();

      return;
    }
    if (tableConfig && tableConfig.visibleColumns) {
      this.visibleColumns = [];
      tableConfig.visibleColumns.forEach((vc) => {
        // if saved columns still exists in list of available columns then let's allow them :)'
        if (!!this.columns.find((c) => c.fieldName === vc)) {
          this.visibleColumns.push(vc);
        }
      });

      this.columns.forEach((c) => {
        if (c.visible !== 'mandatory') {
          c.visible = this.visibleColumns.includes(c.fieldName);
        }
      });

      const sortColumn = !!this.columns.find(
        (x) => x.fieldName === tableConfig.sortColumn || 'name'
      )
        ? tableConfig.sortColumn || 'name'
        : undefined;
      if (sortColumn && this.sort) {
        this.sort.sort({
          id: sortColumn ?? 'name',
          start: tableConfig.sortDir ?? 'asc',
          disableClear: this.matSortDisableClear,
        });
      }
    }
  }

  saveTableConfig() {
    const configKey = this.getTableConfigKey();
    if (!configKey) return;
    const newConfig: ITableCOnfig = {
      visibleColumns: this.visibleColumns,
      sortColumn: this.sort.active,
      sortDir: this.sort.direction,
    };
    if (this.showMultiSelect && !this.visibleColumns.includes('select')) {
      newConfig.visibleColumns!.unshift('select');
    }
    this.localStorageService.store(configKey, newConfig);
  }

  setVisibleColumns(save: boolean = false) {
    console.log('setVisibleColumns');

    if (!this.columns) return;

    this.visibleColumns = [];
    let filterVisibleColumns = [...this.columns].filter(
      (column) => column.visible
    );
    if (this.showMultiSelect) {
      this.visibleColumns.push('select');
    }
    filterVisibleColumns.forEach((column) => {
      this.visibleColumns.push(column.fieldName);
    });
    if (this.showDeleteRow) {
      this.visibleColumns.push('deleteRow');
    }
    if (save) {
      this.saveTableConfig();
    }
  }

  sortChanged(event: Sort) {
    this.saveTableConfig();
    this.sortSignal.set(event);
  }

  isBoolean(val: unknown): boolean {
    return typeof val === 'boolean';
  }

  //TODO: implement with column click to support multiple checkbox fields
  emitCheckboxChange(row: unknown, e: MatCheckboxChange) {
    this.rowCheckboxChange.emit(row);
  }

  applyFilter(searchText: string) {
    this.filterValue.set(searchText);
    // const setfilterValue = this.filterValue.set(this.filterModel);
  }
  clearFilter() {
    this.filterValue.set('');
  }
  setfilterValue() {
    console.log('setfilterValue', this.filterForm);
    // this.filterValue.set(this.filterForm.search || '');
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    if (!this.data()) return false;
    return this.selection.selected.length === this.data().length;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    this.isAllSelected() ? this.selection.clear() : this.selectAllRows();

    this.selectedRowsChange.emit(
      this.data().filter((x) => this.selection.selected.some((y) => y === x.id))
    );
  }

  selectAllRows() {
    this.selection.select(...this.dataSource().map((x) => x.id));
    this.selectedRowsChange.emit(
      this.data().filter((x) => this.selection.selected.some((y) => y === x.id))
    );
  }

  isRowSelected(row: any): boolean {
    // console.log(`is ${row.id} Selected:`, this.selection.isSelected(row));

    return this.selection.isSelected(row.id);
  }

  toggleSelection(row: any) {
    this.selection.toggle(row.id);
    this.selectedRows = this.selection.selected;

    this.selectedRowsChange.emit(
      this.data().filter((x) => this.selection.selected.some((y) => y === x.id))
    );
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${
      this.selection.isSelected(row.id) ? 'deselect' : 'select'
    } row ${row}`;
  }

  deleteRow(row: unknown) {
    this.rowDelete.emit(row);
  }

  dropTable(event: CdkDragDrop<any[]>) {
    console.log('dropTable', event);

    this.dataDropped.emit(event);
    dragDropCursorUtil.cursorReset();
  }

  dragStart(event: CdkDragStart) {
    console.log('dragStart', event);
    dragDropCursorUtil.cursorGrabbing();
  }

  onRowClick(row: any, event: Event) {
    this.rowClick.emit(row);
    // emtpy selection
    this.selection.clear();
  }
  // getFieldValue(row: any, fieldName: string){
  //   return fieldName.split('.').reduce((r, fn) => r && r[fn], row);}

  getFieldValue(row: any, fieldName: string): string {
    // const fieldValue = _.get(row, fieldName, '-');
    // return fieldValue;

    if (!fieldName.includes('.')) return row[fieldName];
    const value = getHierarchicalFieldValue(row, fieldName);
    if (value === null) {
      return '-';
    }
    return value;
  }

  getCustomTemplate(template: string): TemplateRef<any> | undefined {
    return this.customComponent.find((t) =>
      (<any>t)._declarationTContainer?.localNames?.includes(template)
    );
  }

  sortByField(array: any[], sort: Sort | undefined): any[] {
    if (!sort?.active || !sort?.direction) return array;
    const sorted = [...array].sort((a, b): number => {
      if ('isGroup' in a || 'isGroup' in b) return 0;

      if (
        this.getFieldValue(a, sort.active).toLowerCase() >
        this.getFieldValue(b, sort.active).toLowerCase()
      )
        return sort.direction === 'asc' ? 1 : -1;

      if (
        this.getFieldValue(a, sort.active).toLowerCase() <
        this.getFieldValue(b, sort.active).toLowerCase()
      )
        return sort.direction === 'asc' ? -1 : 1;

      return 0;
    });
    return [...sorted];
  }

  /**
   * Groups the @param data by distinct values of a @param column
   * This adds group lines to the dataSource
   * @param reducedGroups is used localy to keep track of the colapsed groups
   * @param sort is done directly on the datasource, instead of ordinary
   * behavior where sorting is done at render time
   */
  groupBy(
    column: IDesignageDataTableColumns | null,
    data: any[],
    reducedGroups: IGroupedRow[] | null = null,
    sort: Sort,
    filterValue: string
  ) {
    // Filter function
    const filterData = (item: any) => {
      if (!filterValue) return true;
      return Object.values(item).some((value) =>
        String(value).toLowerCase().includes(filterValue.toLowerCase())
      );
    };

    // Filter data
    if (filterValue !== '') {
      console.log('filterValue', filterValue);
      console.log(data.filter(filterData));
      // Apply filter to data
      data = data.filter(filterData);
    }

    // If column is null, sort data by field
    if (column === null) return this.sortByField(data, sort);

    const collapsedGroups =
      reducedGroups === null ? ([] as IGroupedRow[]) : reducedGroups;
    const customReducer = (
      accumulator: { [x: string]: (IGroupedRow | ['__typename'])[] },
      currentValue: IGroupedRow | ['__typename']
    ) => {
      const currentGroup =
        this.getFieldValue(currentValue, column.fieldName) !== null
          ? this.getFieldValue(currentValue, column.fieldName)
          : ' ';

      const translatedGroupName = this.translate.instant(column.name);
      if (!accumulator[currentGroup])
        accumulator[currentGroup] = [
          {
            groupName: `${translatedGroupName} > ${this.translate.instant(
              currentGroup
            )}`,
            value: currentGroup,
            isGroup: true,
            reduced:
              collapsedGroups.some((group) => group.value === currentGroup) ??
              false,
          },
        ];
      accumulator[currentGroup].push(currentValue);

      return accumulator;
    };

    let groups: IGroup = this.sortByField(data, sort).reduce(customReducer, {});

    let groupArray = Object.keys(groups).map((key) => groups[key]);
    let flatList = groupArray.reduce((a, c) => {
      return a.concat(c);
    }, []);
    return flatList.filter((row: IGroupedRow | ['__typename']) => {
      const res =
        (row as IGroupedRow).isGroup ||
        collapsedGroups?.every(
          (group) =>
            this.getFieldValue(row as ['__typename'], column.fieldName) !=
            group.value
        );
      return res;
    });
  }

  /**
   * Since groups are on the same level as the data,
   * this function is used by @input(matRowDefWhen)
   */
  isGroup(index: number, item: { isGroup: boolean }): boolean {
    return item.isGroup;
  }

  /**
   * Used in the view to collapse a group
   * Effectively removing it from the displayed datasource
   */
  reduceGroup(row: IGroupedRow) {
    row.reduced = !row.reduced;
    row.reduced
      ? this.reducedGroups.update((reduced) => [...reduced, row])
      : this.reducedGroups.update((reduced) =>
          reduced.filter((el) => el.value != row.value)
        );
  }
}

@if(showFilter || columnSelector){
<div class="d-flex align-items-center justify-content-between m-2 ms-0">
  <div class="flex-grow-1">
    @if(showFilter){
    <form
      [formGroup]="filterForm"
      class="position-relative search-field"
      [class.w-100]="filterForm.value !== ''"
    >
      <app-search-input
        class="w-100"
        [debounceDelay]="500"
        [parentForm]="filterForm"
        (search)="applyFilter($event)"
      ></app-search-input>
    </form>
    }
  </div>

  <div class="d-flex align-items-center">
    @if(showGroupBy()){
    <div ngbDropdown class="me-3">
      <button
        type="button"
        class="btn btn-sm"
        [ngClass]="groupByColumn() === null ? 'btn-outline-light' : 'btn-light'"
        id="dropdownBasic1"
        ngbDropdownToggle
      >
        {{ 'GROUP_BY' | translate }}
        @if(groupByColumn() != null){
        <span class="ms-3 badge rounded-pill bg-primary">
          {{ groupByColumn()?.name | translate }}
        </span>
        <span
          class="ms-1 badge bg-secondary"
          (click)="groupByColumn.set(null); $event.preventDefault()"
          >X</span
        >
        }
      </button>
      <div
        class="form-control form-control-dark shadowed-box"
        ngbDropdownMenu
        aria-labelledby="groupBy"
      >
        @for(col of columns; track col.fieldName){ @if(col.groupable){
        <div
          class="my-1 cursor-pointer"
          ngbDropdownItem
          (click)="groupByColumn.set(col)"
        >
          {{ col.name | translate }}
        </div>
        } }
      </div>
    </div>
    } @if(columnSelector){
    <div ngbDropdown autoClose="outside">
      <button
        class="btn btn-sm btn-outline-light"
        ngbDropdownToggle
        role="button"
      >
        {{ 'COLUMN_SELECTOR' | translate }}
      </button>
      <div
        class="form-control form-control-dark shadowed-box"
        ngbDropdownMenu
        aria-labelledby="ColumnSelector"
      >
        @for(column of columns; track column.fieldname; let i = $index){
        @if(column.visible != 'mandatory'){
        <div ngbDropdownItem>
          <mat-checkbox
            color="primary"
            [(ngModel)]="column.visible"
            (change)="setVisibleColumns(true)"
          />
          <span>
            {{ column.name | translate }}
          </span>
        </div>
        } }
        <hr />
      </div>
    </div>
    }
  </div>
</div>
}
<!-- Loading bar -->
@if(loading()){
<div class="position-relative">
  <div class="position-absolute progress" @showHideLoader>
    <div
      class="progress-bar-striped progress-bar-animated progress-bar-table"
      role="progressbar"
      aria-valuenow="100"
      aria-valuemin="0"
      aria-valuemax="100"
    ></div>
  </div>
</div>
}

<!-- Table -->
<div class="designage-data-table-wrapper overflow-auto">
  <!-- Bulk Actions -->
  @if(showBulkActions && selection.hasValue()){
  <div
    class="d-flex align-items-center overflow-hidden rounded-top bulk-action-row"
    [@showHideBulkActions]
  >
    <h5 class="p-2 text-nowrap">{{ 'BULK_ACTION' | translate }}:</h5>
    <div class="d-flex p-2 flex-wrap overflow-hidden">
      <!-- Bulk Actions injected from parent component -->
      <ng-content select="bulkActions"></ng-content>
    </div>
  </div>
  }

  <div class="overflow-auto h-100 rounded">
    <mat-table
      #table
      [id]="tableElementId"
      class="designage-data-table"
      matSort
      (matSortChange)="sortChanged($event)"
      [matSortDisableClear]="matSortDisableClear"
      [dataSource]="dataSource()"
      [trackBy]="trackBy"
      cdkDropList
      [cdkDropListData]="dataSource()"
      (cdkDropListDropped)="dropTable($event)"
      [cdkDropListConnectedTo]="connectedTableList"
      (cdkDragStarted)="dragStart($event)"
    >
      <!-- Select All Checkbox Column -->
      @if(showMultiSelect){
      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            color="primary"
            (change)="$event ? toggleAllRows() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            [aria-label]="checkboxLabel()"
          >
          </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" (click)="$event.stopPropagation()">
          <mat-checkbox
            color="primary"
            (change)="$event ? toggleSelection(row) : null"
            [checked]="isRowSelected(row)"
            [aria-label]="checkboxLabel(row.name)"
          >
          </mat-checkbox>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
      </ng-container>
      }

      <!-- Delete Row Column -->
      @if(showDeleteRow){
      <ng-container matColumnDef="deleteRow">
        <mat-header-cell *matHeaderCellDef> </mat-header-cell>
        <mat-cell *matCellDef="let row">
          @if(!readOnly){
          <div
            class="cursor-pointer fs-5"
            aria-label="{{ 'DELETE_ROW' | translate }}"
            ngbTooltip="{{ 'DELETE_ROW' | translate }}"
            (click)="deleteRow(row)"
          >
            <i class="ri-close-circle-line"></i>
          </div>
          }
        </mat-cell>
        <mat-footer-cell *matFooterCellDef> </mat-footer-cell> </ng-container
      >}

      <!-- columns generated by [columns] input -->
      @for(column of columns; track column.fieldName; let i = $index, first =
      $first, last = $last){
      <ng-container [matColumnDef]="column.fieldName">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          [disabled]="column.disableSorting"
          class="fs-5 fw-bold"
          [style]="column.style ? column.style : ''"
          [style.flex]="column.flex ? column.flex : '1'"
        >
          {{ column.name | translate }}
        </mat-header-cell>
        <mat-cell
          *matCellDef="let rowElement"
          [style]="column.style ? column.style : ''"
          [style.flex]="column.flex ? column.flex : '1'"
        >
          @if(column.fieldName === 'name'){
          <span class="text-truncate">
            {{ rowElement[column.fieldName] }}
          </span>
          }@else {
          <table-child-cell
            [rowElement]="rowElement"
            [column]="column"
            [customComponent]="customComponent"
          />
          }
        </mat-cell>
      </ng-container>
      }

      <mat-header-row
        *matHeaderRowDef="visibleColumns; sticky: true"
      ></mat-header-row>

      <mat-row
        *matRowDef="let row; columns: visibleColumns"
        cdkDrag
        [cdkDragData]="row"
        [cdkDragDisabled]="dragDisabledRows(row)"
        (cdkDragStarted)="dragStart($event)"
        [cdkPlaceholderTemplate]="cdkPlaceholderPortal"
        (click)="onRowClick(row, $event)"
        [class.row-selected]="
          selection.isSelected(row.id) && !rowActiveInSlidingPanel
        "
        [class.row-in-sliding-panel]="rowActiveInSlidingPanel === row.id"
        [class.no-device-connected]="!row.status"
        [class.status-offline]="
          (row.status && row.status?.Status === 4) ||
          (row.devices && row.devices.length === 0)
        "
        [ngClass]="customDragClass"
      >
        <!-- [class.status-online]="row.status.Status === 1" -->
      </mat-row>

      <ng-container matColumnDef="footer">
        <mat-footer-cell
          *matFooterCellDef
          class="fs-6 mx-4 d-flex justify-content-between"
        >
          {{ footerText }}
          <ng-content select="[footerTemplate]"></ng-content>
        </mat-footer-cell>
      </ng-container>

      <mat-footer-row
        [hidden]="!showFooter"
        *matFooterRowDef="['footer']; sticky: true"
      >
      </mat-footer-row>

      <!-- Group header cells-->
      <ng-container matColumnDef="groupReducer">
        <mat-cell
          colspan="1"
          *matCellDef="let group"
          style="flex: 0; padding-left: 6.5rem; min-width: 8rem"
        >
          <div>
            @if(!group.reduced){
            <mat-icon>expand_less</mat-icon>
            } @else{
            <mat-icon>expand_more</mat-icon>
            }
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="groupHeader">
        <mat-cell
          colspan="999"
          *matCellDef="let group"
          style="border-left: unset !important"
        >
          <h4>
            {{ 'GROUPED_BY' | translate }}: {{ group.groupName | titlecase }}
          </h4>
        </mat-cell>
      </ng-container>

      <!-- Group line -->
      <mat-row
        class="clickable group-row"
        *matRowDef="
          let row;
          columns: ['groupReducer', 'groupHeader'];
          when: isGroup
        "
        (click)="reduceGroup(row)"
      ></mat-row>
    </mat-table>
  </div>
</div>

import { CommonModule, NgStyle } from '@angular/common';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { debounceTime, map } from 'rxjs/operators';
import { SubSink } from 'subsink';

@Component({
  standalone: true,
  imports: [
    NgStyle,
    NgbTooltip,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  selector: 'app-search-input',
  template: `
    <form [formGroup]="parentForm" class="d-flex align-items-center">
      <div
        class="input-group flex-nowrap align-items-center search-field"
        [class.w-100]="searchInput.value !== ''"
      >
        <input
          #searchInput
          type="{{ inputType }}"
          (keydown.enter)="emitSearchValue(searchInput.value)"
          (keydown.escape)="clearFilterInput()"
          class="form-control form-control-dark search-input"
          formControlName="search"
          placeholder="{{ placeholder | translate }}"
          [ngStyle]="inputStyle"
        />
        <button
          (click)="clearFilterInput()"
          [hidden]="parentForm?.controls.search?.pristine"
          class="btn-close btn-close-white position-absolute"
          style="right: 0.25rem; z-index: 10"
          aria-label="Close"
        ></button>
      </div>

      <!-- This div contains the searchMode switch -->
      @if(globalSearch){
      <div
        class="mx-2 btn-group btn-group-sm"
        name="searchMode"
        aria-label="Set folder or global search"
        style="height: fit-content"
        [hidden]="parentForm?.controls.search?.pristine"
      >
        <input
          class="btn-check"
          type="radio"
          formControlName="globalSearch"
          [checked]="!globalSearch.value"
          [value]="false"
        />
        <label
          for="folderSearch"
          class="btn btn-outline-light"
          ngbTooltip="{{ 'SEARCH_FOLDER_TT' | translate }}"
          (click)="onGlobalSearchButtonClicked(false)"
        >
          <div>{{ 'SEARCH_FOLDER' | translate }}</div>
        </label>

        <input
          class="btn-check"
          type="radio"
          formControlName="globalSearch"
          [checked]="globalSearch.value"
          [value]="true"
        />
        <label
          for="globalSearch"
          class="btn btn-outline-light"
          ngbTooltip="{{ 'SEARCH_GLOBAL_TT' | translate }}"
          (click)="onGlobalSearchButtonClicked(true)"
        >
          <div>{{ 'SEARCH_GLOBAL' | translate }}</div>
        </label>
      </div>
      }
    </form>
  `,
  styleUrls: ['./search-input.component.scss'],
})
export class SearchInputComponent implements OnInit, OnDestroy {
  private subs = new SubSink();

  @Input() placeholder = 'SEARCH';
  @Input() inputType = 'text';
  @Input() inputStyle = {};
  @Input() debounceDelay = 1000;
  @Output() search = new EventEmitter<string | number>();

  private emittedValue!: string | number;

  // media search form variables
  @Input() parentForm?: FormGroup;
  globalSearch?: AbstractControl;

  constructor() {}

  ngOnInit() {
    this.setFormControlState();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  setFormControlState() {
    if (this.parentForm) {
      // optional field from parent: globalSearch
      this.globalSearch = this.parentForm.controls.globalSearch;

      this.subs.sink = this.parentForm.controls.search.valueChanges
        .pipe(
          debounceTime(this.debounceDelay),
          map((value) => {
            if (!this.globalSearch) return value;

            // note: this prevents globally fetching everything
            // if searchText value is empty and serverSideSearch state is true
            // then set serverSideSearch state to false
            if (value === '') {
              this.globalSearch.setValue(false);
            }

            return value;
          })
        )
        .subscribe((value) => {
          this.emitSearchValue(value);
          value ? '' : this.parentForm?.markAsPristine();
        });
    }
  }

  emitSearchValue(value: string | number) {
    if (this.emittedValue !== value) {
      this.search.emit(value);
      this.emittedValue = value;
    }
  }

  clearFilterInput() {
    if (this.parentForm) {
      this.parentForm.patchValue({ search: '' });
      this.parentForm.markAsPristine();
      this.emitSearchValue('');
    }
  }

  onGlobalSearchButtonClicked(global: boolean) {
    if (!this.globalSearch)
      return console.error('no globalSearch field in parent form');

    this.globalSearch.setValue(global);
  }
}
